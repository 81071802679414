import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import "../styles/index.sass";

const HeroBanner = styled.section`
  background-image: linear-gradient(
      0deg,
      ${({ topGradient }) => topGradient},
      ${({ bottomGradient }) => bottomGradient}
    ),
    url(${({ background }) => background});
  background-size: cover;
  height: 50vh;
  background-attachment: fixed;
  display: grid;
  align-items: center;
  h1 {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 3px;
    @media (max-width: 600px) {
      font-size: 35px;
    }
  }
`;

const Nav = styled.nav`
  background: #ffffffbf;
  position: fixed;
  width: 100%;
  top: 0;
  button {
    list-style-type: none;
    display: inline;
    color: #000000;
    text-transform: uppercase;
    -webkit-letter-spacing: 5px;
    -moz-letter-spacing: 5px;
    -ms-letter-spacing: 5px;
    letter-spacing: 5px;
    font-size: 15px;
    font-weight: 900;
    background: none;
    border: none;
    font-family: "Karla";
    padding: 0;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
  a {
    height: 75px;
  }
  z-index: 10000;
  border-bottom: 3px solid black;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  transition: all 1.5s ease;
  &.white {
    background: #ffffff;
    transition: all 0.5s ease;
  }
  @media (max-width: 1350px) {
    display: block;
  }
  @media (max-width: 475px) {
    display: grid;
    grid-template-columns: 75px 1fr 75px;
    align-items: flex-start;
  }
  @media (max-width: 475px) {
    grid-template-columns: 75px 1fr 75px;
  }
  ul {
    text-align: right;
    &.main-nav {
      li {
        position: relative;
        padding: 15px;
        a {
          list-style-type: none;
          display: inline;
          color: #000000;
          text-transform: uppercase;
          -webkit-letter-spacing: 5px;
          -moz-letter-spacing: 5px;
          -ms-letter-spacing: 5px;
          letter-spacing: 5px;
          font-size: 15px;
          font-weight: 900;
          background: none;
          border: none;
        }
        span {
          cursor: pointer;
        }
      }
      @media (max-width: 1350px) {
        position: absolute;
        top: 163px;
        width: 100%;
        background: #ffffff;
        height: 100vh;
        left: -2000px;
        display: block;
        transition: left 0.5s ease;
        @media (max-width: 600px) {
          top: 155px;
        }
        @media (max-width: 475px) {
          top: 106px;
        }
        &.show {
          left: 0;
          padding: 50px 0 0 0;
          transition: left 0.5s ease;
          li {
            display: list-item;
            text-align: center;
          }
        }
        &.hide {
          left: -2000px;
          transition: left 0.5s ease;
          padding: 50px 0 0 0;
          li {
            display: list-item;
            text-align: center;
          }
        }
      }
    }
    li {
      list-style-type: none;
      display: inline;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 15px;
      font-weight: 900;
      a {
        color: #000000;
        text-decoration: none;
      }
    }
  }
`;

const EmailPhone = styled.ul`
  grid-column-start: 2;
  grid-column-end: 3;

  @media (max-width: 475px) {
    span {
      display: grid;
      grid-template-columns: 50px 50px;
      margin: 0 auto;
      justify-items: center;
    }
    &.mob-phone {
      text-align: center;
      padding: 0;
      li {
        display: list-item;
        &.facebook {
          display: none;
        }
        @media (max-width: 475px) {
          font-size: 12px;
          letter-spacing: 3px;
          padding: 15px 0 15px 0;
        }
        a {
          background: #54b554;
          padding: 10px;
          width: 100px;
          border-radius: 23px;
          color: #ffffff;
        }
      }
    }
  }
  li {
    padding: 0 15px 0 15px;
    &.lighter {
      font-weight: 100;
    }
  }
`;

const Hamburger = styled.span`
  padding: 35px 50px 0 0;
  font-size: 22px;
  @media (max-width: 475px) {
    padding: 10px 10px 0px 0;
  }
  svg {
    height: 2em;
    width: 2em !important;
    @media (max-width: 475px) {
      height: 1.75em;
    }
  }
`;

const ImageLogo = styled.img`
  width: 75px;
  display: block;
  padding: 25px;
  @media (max-width: 1200px) {
    &.active-ham {
    }
  }
  @media (max-width: 600px) {
    width: 50px;
    padding: 10px;
  }
`;

const Footer = styled.div`
  background: grey;
  padding: 100px;
  text-align: center;
  display: grid;
  justify-items: center;
  @media (max-width: 650px) {
    padding: 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 2px;
    color: #ffffff;
    width: 625px;
    line-height: 32px;
    padding: 0 0 50px 0;
    @media (max-width: 650px) {
      width: 90%;
    }
  }
`;

const Title = styled.h1`
  text-align: center;
  padding-top: 100px;
`;

// *Scroll Function*

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

// *Scroll Function Ends*

// *hook*

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, deps);
}

// *hook ends*

const TemplateWrapper = ({ children }) => {
  // *running function*

  const [hideOnScroll, setHideOnScroll] = useState(false);
  const shadowStyle = hideOnScroll ? "white" : "";

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < -100;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  //hamburger menu

  const [state, setState] = useState(false);

  function toggle() {
    setState(!state);
    console.log(state);
  }

  return (
    <StaticQuery
      query={graphql`
        query LayoutQueryAvailability {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            introHeader
            copyright
            legalCopy
            mapHeader
            homeHeroImage {
              url
              title
            }
            topGradientColor {
              rgb
              hex
            }
            bottomGradientColor {
              rgb
              hex
            }
          }
          datoCmsHeader {
            logo {
              url
            }
          }
          datoCmsSocialProfile {
            facebookUrl
            email
            phoneNumber
          }
          datoCmsAvailability {
            title
          }
        }
      `}
      render={(data) => (
        <div className="container">
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
            title="test"
          />

          <Nav className={`header ${shadowStyle}`}>
            <Link to="/">
              <ImageLogo
                className={state ? `active-ham` : ``}
                src={data.datoCmsHeader.logo.url}
              />
            </Link>
            <ul className={state ? `main-nav show` : `main-nav hide`}>
              <li>
                <Link to="/#pricing">Individual Pricing</Link>
              </li>
              <li>
                <Link to="/#groupPricing">Group Pricing</Link>
              </li>

              <li>
                <Link to="/#testimonials">Testimonials</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/availability">Availability</Link>
              </li>
              <li>
                <Link to="/#contact">Contact</Link>
              </li>
            </ul>

            <EmailPhone
              className={state ? `mob-phone active-ham` : `mob-phone`}
            >
              <Hamburger onClick={toggle} className="hamTog">
                <FontAwesomeIcon icon={faBars} />
              </Hamburger>

              <li className="lighter">
                <a href={`mailto:` + data.datoCmsSocialProfile.email}>
                  {data.datoCmsSocialProfile.email}
                </a>
              </li>
              <li className="lighter">
                <a href={`tel:` + data.datoCmsSocialProfile.phoneNumber}>
                  {data.datoCmsSocialProfile.phoneNumber}
                </a>
              </li>
              <span>
                <li className="facebook">
                  <a href={data.datoCmsSocialProfile.facebookUrl}>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
              </span>
            </EmailPhone>
          </Nav>
          {/* <div className="container__mobile-header">
          <div className="mobile-header">
            <div className="mobile-header__menu">
              <Link to="#" data-js="toggleSidebar" />
            </div>
            <div className="mobile-header__logo">
              <Link to="/">{data.datoCmsSite.globalSeo.siteName}</Link>
            </div>
          </div>
        </div> */}

          <HeroBanner
            background={data.datoCmsHome.homeHeroImage.url}
            topGradient={data.datoCmsHome.topGradientColor.hex}
            bottomGradient={data.datoCmsHome.bottomGradientColor.hex}
          ></HeroBanner>

          {/* <Intro>
        <h2>{data.datoCmsHome.introHeader}</h2>
        <p>{data.datoCmsHome.introText}</p>
    </Intro> */}

          <div>
            <Title>{data.datoCmsAvailability.title}</Title>

            {children}
          </div>

          <Footer className="copyright">
            <h4>{data.datoCmsHome.mapHeader}</h4>

            <p>{data.datoCmsHome.copyright}</p>
            <p>{data.datoCmsHome.legalCopy}</p>
          </Footer>
        </div>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default TemplateWrapper;
