import React, { useState } from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Img from "gatsby-image";
import LayoutAvailability from "../components/layoutAvailability";
import styled from "styled-components";
import Loader from "../components/loader";

const Times = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  padding: 0;
  @media (max-width: 1350px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
  h2 {
    text-align: center;
  }
  span {
    width: 100px;
  }
  span:first-child {
    background: red;
  }
  span:last-child {
    background: purple;
  }
`;

const Header = styled.h2`
  text-align: center;
`;

const Body = styled.div`
  text-align: center;
`;

const TimesSection = styled.section`
  background: #ffffff;
  width: 75%;
  margin: 0 auto;
  .sheet__inner {
    margin: 100px 0;
  }
`;

const CheckBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  label {
    display: grid;
    justify-items: center;
    text-align: center;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #777777;
  }
  .pretty {
    margin-right: 0;
    font-size: 32px;
  }
  .pretty .state label:before {
    background: #ffffff;
  }
`;

const AvailableForm = styled.form`
  background: lightgrey;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  justify-items: center;
  padding: 75px;
  #contact {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 750px) {
    display: block;
  }
  h3 {
    span {
      color: red;
    }
  }
  label {
    width: 100%;
  }
  .checky {
    width: 100%;
  }
  input[type="text"],
  input[type="email"] {
    padding: 12px 12px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    &:focus {
      outline: 0;
    }
  }
  .text-area {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    textarea {
      height: 150px;
      border: none;
      box-sizing: border-box;
      width: 100%;
      border-radius: 20px;
      border: none;
      padding: 12px 12px;
      font-size: 20px;
      border: none;
      box-sizing: border-box;
      &:focus {
        outline: 0;
      }
    }
  }
  .submitHand {
    grid-column-start: 1;
    grid-column-end: 3;
    button {
      width: 100%;
      margin-top: 25px;
      border: none;
      background: green;
      color: #ffffff;
      padding: 20px;
      font-size: 32px;
      border-radius: 20px;
    }
    &.fail {
      a,
      span {
        display: block;
        text-align: center;
      }
      button {
        background: grey;
      }
    }
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 150px;
  background: #adadad;
  @media (max-width: 1350px) {
    grid-template-columns: 1fr;
  }
  h2 {
    color: #ffffff;
    align-self: center;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;

const ThankYou = styled.div`
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding: 150px;
`;

const About = ({ data: { about } }, data) => {
  const [formState, setFormState] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    reasons: {
      weightLoss: false,
      strength: false,
      sport: false,
    },
  });

  console.log("pushAgain");

  const formStateCheck = formState;

  const checkValidationName = () => {
    if (formStateCheck.name === "") {
      setShowMessageName(true);
    } else {
      setShowMessageName(false);
    }
  };

  const checkValidationEmail = () => {
    if (formStateCheck.email === "") {
      setShowMessageEmail(true);
    } else {
      setShowMessageEmail(false);
    }
  };

  const checkValidationSubject = () => {
    if (formStateCheck.subject === "") {
      setShowMessageSubject(true);
    } else {
      setShowMessageSubject(false);
    }
  };

  const checkValidationMessage = () => {
    if (formStateCheck.message === "") {
      setShowMessageMessage(true);
    } else {
      setShowMessageMessage(false);
    }
  };

  const checkValidationSubmit = () => {
    const formStateCheck = formState;

    if (
      formStateCheck.name !== "" &&
      formStateCheck.email !== "" &&
      formStateCheck.subject !== "" &&
      formStateCheck.message !== ""
    ) {
      setFormInvalid(true);
    }
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      const changedReason = e.target.getAttribute("name");
      setFormState({
        ...formState,
        reasons: {
          ...formState.reasons,
          [changedReason]: !formState.reasons[changedReason],
        },
      });
      console.log(formState);
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    checkValidationSubmit();
  };

  const prepareDataForApi = (formData) => {
    const newFormData = Object.assign({}, formData); // optional if passed cloned copy of formData object or you can also use lodash cloneDeep
    newFormData.data = {
      reasonArray: [],
    };

    Object.keys(newFormData.reasons).forEach((key) => {
      if (newFormData.reasons[key]) {
        newFormData.data.reasonArray.push({ reason: key });
      }
    });

    delete newFormData.reasons;

    return newFormData;
  };

  //Form Errors and Hiding and Showing

  const [form, setForm] = useState(true);

  function showForm(e) {
    e.preventDefault();
    setForm(true);
  }

  const [formFail, setFormFail] = useState(true);

  function formFailReset(e) {
    e.preventDefault();
    setFormFail(true);
  }

  const [formInvalid, setFormInvalid] = useState(false);

  // const [nameVal, setNameVal] = useState(false);

  const [showMessageName, setShowMessageName] = useState(false);

  const [showMessageEmail, setShowMessageEmail] = useState(false);

  const [showMessageSubject, setShowMessageSubject] = useState(false);

  const [showMessageMessage, setShowMessageMessage] = useState(false);

  const [loading, setLoading] = useState(true);

  //End

  // Try Catch

  //End

  const submitForm = async (e) => {
    e.preventDefault();

    //All validation
    if (
      formState.name === "" ||
      formState.email === "" ||
      formState.subject === "" ||
      formState.message === ""
    ) {
      setFormInvalid(false);
    } else {
      setFormInvalid(true);

      const newFormData = prepareDataForApi(formState);
      setForm(false);
      setLoading(false);

      try {
        const response = await fetch("/.netlify/functions/sendmail", {
          method: "POST",
          body: JSON.stringify(newFormData),
        }).then(() => {
          setLoading(true);
        });

        if (!response.ok) {
          console.log(response);

          setFormFail(false);

          return;
        }

        console.log("success email");
      } catch (e) {
        console.log("error");
      }
    }
  };

  return (
    <LayoutAvailability>
      <HelmetDatoCms seo={about.seoMetaTags} />

      {about.imBusy ? (
        <TimesSection>
          <Header className="sheet__lead">{about.busyMessage}</Header>
        </TimesSection>
      ) : (
        <TimesSection>
          <div className="sheet__inner">
            <Header className="sheet__lead">{about.subtitle}</Header>
            {/* <div className="sheet__gallery">
              <Img fluid={about.photo.fluid} />
            </div> */}
            <Body
              className="sheet__body"
              dangerouslySetInnerHTML={{
                __html: about.bioNode.childMarkdownRemark.html,
              }}
            />
          </div>

          <Times className="time-list">
            {about.dayAndTimeAvailability.map((detailEntry) => {
              return (
                <div key={detailEntry.id}>
                  <h2>{detailEntry.dayOfWeek}</h2>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: detailEntry.timesNode.childMarkdownRemark.html,
                    }}
                  />
                </div>
              );
            })}
          </Times>
        </TimesSection>
      )}

      {/* // ******Form******/}

      <FormWrapper>
        <Header>{about.contactFormTitle}</Header>

        {form ? (
          <AvailableForm onSubmit={submitForm} autoComplete="off">
            {/* <input type="text" name="package" value={data.datoCmsPricing.title} /> */}
            <label>
              <h3>Name{showMessageName && <span> *Required</span>}</h3>
              <input
                type="text"
                name="name"
                value={formState.name}
                onChange={onChange}
                onKeyUp={checkValidationName}
                autoComplete="off"
              />
            </label>
            <label>
              <h3>Email{showMessageEmail && <span> *Required</span>}</h3>
              <input
                type="email"
                name="email"
                value={formState.email}
                onChange={onChange}
                onKeyUp={checkValidationEmail}
                autoComplete="off"
              />
            </label>
            <label>
              <h3>Subject{showMessageSubject && <span> *Required</span>}</h3>
              <input
                type="text"
                name="subject"
                value={formState.subject}
                onChange={onChange}
                onKeyUp={checkValidationSubject}
              />
            </label>
            <div className="checky">
              <h3>Reasons for wanting to train</h3>
              <CheckBoxes>
                <label>
                  <h4>Weight Loss</h4>
                  <div className="pretty p-smooth p-curve p-fill">
                    <input
                      type="checkbox"
                      name="weightLoss"
                      reason="Weight Loss"
                      checked={formState.reasons.weightLoss}
                      onChange={onChange}
                    />
                    <div className="state p-primary">
                      <label></label>
                    </div>
                  </div>
                </label>
                <label>
                  <h4>Strength</h4>
                  <div className="pretty p-smooth p-curve p-fill">
                    <input
                      type="checkbox"
                      name="strength"
                      reason="Strength"
                      checked={formState.reasons.strength}
                      onChange={onChange}
                    />
                    <div className="state p-primary">
                      <label></label>
                    </div>
                  </div>
                </label>
                <label>
                  <h4>Sport</h4>
                  <div className="pretty p-smooth p-curve p-fill">
                    <input
                      type="checkbox"
                      name="sport"
                      reason="Sport"
                      checked={formState.reasons.sport}
                      onChange={onChange}
                    />
                    <div className="state p-primary">
                      <label></label>
                    </div>
                  </div>
                </label>
                <label for="other">
                  <h4>Other</h4>
                  <div className="pretty p-smooth p-curve p-fill">
                    <input
                      type="checkbox"
                      name="other"
                      reason="Other"
                      checked={formState.reasons.other}
                      onChange={onChange}
                    />
                    <div className="state p-primary">
                      <label for="other"></label>
                    </div>
                  </div>
                </label>
              </CheckBoxes>
            </div>

            <div className="text-area">
              <label>
                <h3>Message{showMessageMessage && <span> *Required</span>}</h3>
                <textarea
                  name="message"
                  value={formState.message}
                  onChange={onChange}
                  onKeyUp={checkValidationMessage}
                />
              </label>
            </div>

            {formFail ? (
              <label className={formInvalid ? `submitHand` : `submitHand fail`}>
                {formInvalid ? (
                  <button type="submit">Submit</button>
                ) : (
                  <button disabled type="submit">
                    Submit
                  </button>
                )}
              </label>
            ) : (
              <label className="submitHand fail">
                <span>Something Went Wrong</span>
                <a onClick={formFailReset} href="#">
                  Click to reset form
                </a>
                <button disabled type="submit">
                  Submit
                </button>
              </label>
            )}
          </AvailableForm>
        ) : (
          <>
            {loading ? (
              <ThankYou>
                <div>
                  <p>Thank you your message has been sent</p>
                </div>

                <a onClick={showForm} href="#">
                  Send Again
                </a>
              </ThankYou>
            ) : (
              <ThankYou>
                <Loader />
              </ThankYou>
            )}
          </>
        )}
      </FormWrapper>

      {/* // ****** FOrm ENds ****/}
    </LayoutAvailability>
  );
};

export default About;

export const query = graphql`
  query AboutQuery {
    about: datoCmsAvailability {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      imBusy
      subtitle
      busyMessage
      contactFormTitle
      dayAndTimeAvailability {
        times
        dayOfWeek
        id
        timesNode {
          childMarkdownRemark {
            html
          }
        }
      }
      bioNode {
        childMarkdownRemark {
          html
        }
      }
    }
    datoCmsPricing {
      title
    }
  }
`;
